import React from "react"
import theme from 'utils/theme'
import { css } from 'emotion'
import resourceTypeDetails from 'utils/ResourceTypeDetails'

const CardWrapper = {
  margin: `0 0 40px 0`,

  [`${theme.responsiveKeys.mobile}`]: {
    margin: `0 0 24px 0`,
  },
}

const CardStyle = {
  padding: `16px`,
  boxShadow: `rgba(35, 41, 47, 0.06) 0px 4px 20px 0px`,
  borderRadius: `6px`,

  [`${theme.responsiveKeys.mobile}`]: {
    boxShadow: `rgba(35, 41, 47, 0.06) 0px 2px 16px 0px`,
  },
}

const ResourceTypeStyle = {
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  width: `56px`,
  height: `56px`,
  borderRadius: `40px`,
  backgroundColor: theme.colors.white,
  margin: `0`,

  // boxShadow: `0px -4px 6px 0px rgba(35,41,47,0.03)`,

  [`${theme.responsiveKeys.mobile}`]: {
    width: `48px`,
    height: `48px`,
    margin: `0`,
  },
}

const ResourceTypeInnerContainerStyle = {
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  width: `48px`,
  height: `48px`,
  borderRadius: `28px`,
}

const ResourceTypeImageStyle = {
  margin: 0,
  padding: 0,
}

const TitleStyle = {
  margin: `16px 0 0 0`,
}

const AuthorsContainer = {
  margin: `4px 0 0 0`,
}

const AuthorStyle = {
  color: theme.colors.black70,
  fontSize: theme.fontSize.small,
  lineHeight: `1.3em`,
  margin: `0`,
}

const NotesContainer = {
  display: `flex`,
  margin: `0`
}

const TagContainerStyle = {
  display: `flex`,
  flexWrap: `wrap`,
  margin: `8px 0 0 0`,
}

const TagStyle = {
  display: `flex`,
  justtifyContent: `center`,
  margin: `8px 8px 0 0`,
  padding: `6px 12px`,
  borderRadius: `16px`,
  backgroundColor: theme.colors.black20,
  width: `fit-content`,
  height: `fit-content`,
}

const TagTextStyle = {
  fontSize: `14px`,
  lineHeight: `1.3em`,
  fontWeight: theme.fontWeight.regular,
  margin: 0,
  padding: 0,
  color: theme.colors.black80,
}

class BookView extends React.Component {

  filterTags(tags) {
    return tags.filter(tag => (
      tag !== "default"
    ))
  }

  constructAuthorString(authors) {
    const byString = 'By '

    if (authors.length === 1) {
      return `${byString} ${authors[0]}`
    }

    if (authors.length === 2) {
      return `${byString} ${authors[0]} and ${authors[1]}`
    }

    const decoratedAuthors = authors.map(function(author, index) {
      if (index === 0) {
        return author
      } else if (index === authors.length - 1) {
        return `, and ${author}`
      } else {
        return `, ${author }`
      }
    })

    const stringSet = [byString].concat(decoratedAuthors)

    return stringSet.reduce((prev, current) => (
      prev + current
    ))
  }

  render() {
    const { id,
            resourceType,
            title,
            authors,
            link,
            deconstructingDesignSlug,
            tags,
            bookCoverInfo
          } = this.props.book


    const authorString = this.constructAuthorString(authors)
    const availableTags = this.filterTags(tags)
    const resourceTypeBackgroundColor = resourceTypeDetails.colorForResourceType(resourceType.toUpperCase())
    const resourceTypeIcon = resourceTypeDetails.iconForResourceType(resourceType.toUpperCase())


    return (
      <div className='col-4 col-12-sm'>
        <div className={css(CardWrapper)}>
          <div className={css(CardStyle)}>
            <div className={css(ResourceTypeStyle)}>
              <div style={{...ResourceTypeInnerContainerStyle, ...{backgroundColor: resourceTypeBackgroundColor}}}>
                <img style={ResourceTypeImageStyle} src={resourceTypeIcon} alt={resourceType} />
              </div>
            </div>
            <a href={`${link}?tag=deconstruc0b7-20`}>
              <h5 style={TitleStyle}>{title}</h5>
            </a>
            <div style={AuthorsContainer}>
              <p style={AuthorStyle}>{authorString}</p>
            </div>
            <div style={NotesContainer}>
            </div>
            <div style={TagContainerStyle}>
              {
                availableTags.map(tag => (
                  <div style={TagStyle} key={tag}>
                    <p style={TagTextStyle}>{tag}</p>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BookView

/*
bookCover {
  title
  file {
    url
  }
}
*/

