import React from "react"
import { get, debounce } from 'lodash'
import { Link, graphql } from "gatsby"

import Layout from 'layout/layout'
import SEO from 'components/seo'
import theme from 'utils/theme'

import PersonView from 'components/cards/PersonView'
import BookView from 'components/cards/BookView'
import OnlineResourceView from 'components/cards/OnlineResourceView'

const PagePadding = {
  padding: `0 0 40px 0`,
}

const ContentBlockStyle = {
  display: `flex`,
  flexWrap: `wrap`,
  width: `100%`,
  padding: `24px 0 40px 0`,
}

const PageHeaderStyle = {
  margin: `0 0 8px 0`,
}

const SeeMoreLinkContainerStyle = {
  margin: `0 0 40px 0`,
}

const SeeMoreLinkStyle = {
  width: `fit-content`,
  fontSize: `18px`,
  fontWeight: theme.fontWeight.medium,
  color: theme.colors.accent,
  padding: `0 0 3px 0`,
  borderBottom: `2px solid ${theme.colors.accent}`,
  marginTop: `0px`,
}

class ResourcesPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
    }

    this.shuffle = this.shuffle.bind(this)
    this.handleWindowResize = this.handleWindowResize.bind(this);
  };

  componentDidMount() {
    this.handleWindowResize()

    window.addEventListener('resize', debounce(this.handleWindowResize, 200));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.handleWindowResize, 200));
  }

  handleWindowResize() {
    this.setState({ isMobile: window.innerWidth < 480 })
  }  

  shuffle(a) {
    var j, x, i;

    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1))
        x = a[i]
        a[i] = a[j]
        a[j] = x
    }

    return a
  }

  pickRandomSix(list) {
    return this.shuffle(list.slice(0, 6))
  }

  alphabatizeByName() {

  }

  render() {
    const books = get(this, 'props.data.allContentfulBook.edges').map(edge => ( edge.node ))
    const people = get(this, 'props.data.allContentfulPerson.edges').map(edge => ( edge.node ))
    const onlineResources = get(this, 'props.data.allContentfulOnlineResource.edges').map(edge => ( edge.node ))

    const booksToRedner = this.state.isMobile ? books.slice(0,4) : books.slice(0,9)
    const onlineResourcesToRender = this.state.isMobile ? onlineResources.slice(0,4) : onlineResources.slice(0,9)
    const peopleToRender = this.state.isMobile ? people.slice(0,4) : people.slice(0,9)

    return (
      <Layout>
        <SEO title={`Resources`} />
        <div className="container" style={PagePadding}>
          <div className="row">
            <div className="col-12">
              <div style={PageHeaderStyle}>
                <h1>Resources</h1>
                <p>Over the years throughout my own learning experience, I've been curating various resources that have helped me along the way. Below are a few of those, in addition to many recommendations from others across the design community.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Link to={`/resources/books`}><h2>Books</h2></Link>
            </div>
            <div style={ContentBlockStyle}>
              {
                booksToRedner.map(book => (
                  <BookView key={book.id} book={book} />
                ))
              }
              <div className="col-12">
                <div style={SeeMoreLinkContainerStyle}>
                  <Link to={`/resources/books`}><h4 style={SeeMoreLinkStyle}>See more books</h4></Link>
                </div>
              </div>
            </div>
          </div>

          <div id="online-resources" className="row">
            <div className="col-12">
              <Link to={`/resources/online-resources`}><h2>Online resources</h2></Link>
            </div>
            <div style={ContentBlockStyle}>
              {
                onlineResourcesToRender.map(resource => (
                  <OnlineResourceView key={resource.id} onlineResource={resource} />
                ))
              }
              <div className="col-12">
                <div style={SeeMoreLinkContainerStyle}>
                  <Link to={`/resources/online-resources`}><h4 style={SeeMoreLinkStyle}>See more online resources</h4></Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Link to={`/resources/people`}><h2>People</h2></Link>
            </div>
            <div style={ContentBlockStyle}>
              {
                peopleToRender.map(person => (
                  <PersonView key={person.id} person={person} />
                ))
              }
              <div className="col-12">
                <div style={SeeMoreLinkContainerStyle}>
                  <Link to={`/resources/people`}><h4 style={SeeMoreLinkStyle}>See more people</h4></Link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </Layout>
    );
  }
}

export default ResourcesPage

export const pageQuery = graphql`
  query AllResourcesQuery {
    allContentfulBook {
      edges {
        node {
          id
          resourceType
          title
          authors
          link
          bookCover {
            title
            file {
              url
            }
          }
          deconstructingDesignSlug
          tags
        }
      }
    }
    allContentfulPerson {
      edges {
        node {
          id
          resourceType
          name
          tags
          website
          youtube
          medium
          linkedin
          dribbble
          instagram
          github
          twitter
        }
      }
    }
    allContentfulOnlineResource {
      edges {
        node {
          id
          resourceType
          title
          authors
          link
          deconstructingDesignSlug
          tags
        }
      }
    }
  }
`

